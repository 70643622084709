.spinner {
  width: 32px;
  height: 32px;
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-top: 4px solid #8b5cf6;
  border-radius: 50%;
  animation: spin 0.7s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulse-in {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.95);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .pulse-animation {
    animation: pulse-in 0.25s ease-in-out;
  }
  

.cart-button {
  position: relative;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 999px;
  padding: 6px 12px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  backdrop-filter: blur(5px);
  transition: background 0.2s;
  will-change: transform, background-color;
}

.cart-badge {
  position: absolute;
  top: -6px;
  right: -6px;
  background: #ef4444;
  color: white;
  border-radius: 999px;
  padding: 2px 6px;
  font-size: 11px;
  font-weight: bold;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

/* Shake and scale when item is added */
@keyframes cartBumpAnim {
  0% {
    transform: scale(1) rotate(0deg);
    background-color: rgba(255, 255, 255, 0.05);
  }
  20% {
    transform: scale(1.1) rotate(-5deg);
    background-color: white;
  }
  40% {
    transform: scale(1.15) rotate(5deg);
    background-color: rgba(255, 255, 255, 0.3);
  }
  60% {
    transform: scale(1.1) rotate(-3deg);
    background-color: rgba(255, 255, 255, 0.15);
  }
  100% {
    transform: scale(1) rotate(0deg);
    background-color: rgba(255, 255, 255, 0.05);
  }
}

.cart-bump {
  animation: cartBumpAnim 0.4s ease-out;
}

/* Pulse and nudge every 30s */
@keyframes cartRemindAnim {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.05) rotate(-3deg);
  }
  20% {
    transform: scale(1.1) rotate(3deg);
  }
  30% {
    transform: scale(1.05) rotate(-2deg);
  }
  40% {
    transform: scale(1.08);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}

.cart-remind {
  animation: cartRemindAnim 0.6s ease-in-out;
}

.toggle-button {
    padding: 6px 10px;
    background: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: transform 0.2s ease;
  }